import styled from "@emotion/styled";
import { Typography} from "@mui/material";
import React from "react";

const Heading = styled(Typography)``;

const SectionsHeading = (props) => {
  const { children } = props;
  return (
    <Heading
      color="#fff"
      fontFamily="glacious"
      fontSize={"clamp(1.875rem, 3.125vw + 1.25rem, 5rem)"}
      textAlign={"start"}
      textTransform={"capitalize"}
      {...props}
    >
      {children}
    </Heading>
  );
};

export default SectionsHeading;
