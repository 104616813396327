import React from "react";
import { Container} from "@mui/material";
import styled from "@emotion/styled";
import { AbsoluiteImage, CustomGrid, DataImage} from "../../../Styles/style";
import tokenimage from '../../../../assets/images/tokenimage.png';
import burn from '../../../../assets/images/burn.png';
import tax from '../../../../assets/images/tax.png';
import renounced from '../../../../assets/images/renounced.png';
import Paragraph from "../../Paragraph";
import SectionsHeading from "../../SectionsHeading";

const ContinerWraper = styled.div`
  overflow: hidden;
  min-height: calc(100vh - 40px);
  width: 100%;
  background: #000;
  padding: 30px 0 !important;
  position: relative;
  z-index: 0;
  @media (max-width: 599px) {
    padding: 50px 0 0 !important;
  }
`;

const InnerContinerWraper = styled(Container)`
  min-height: calc(100vh - 40px);
  width: 100%;
  display: grid;
  place-items: center;
  z-index: 1;
  @media (max-width: 599px) {
    background-position: left;
    background-image: none;
  }
`;

const TokenImageGrid = styled(CustomGrid)`
  background: linear-gradient(to top,#2A2A2A -20%,transparent  50%);
  border-radius: 20px;
  flex-direction: column;
  justify-content: end;
  min-height: 200px;
  gap:20px;
  padding: 20px 20px 30px;
`
const Tokenomics = () => {
  return (
    <>
      <ContinerWraper maxWidth="xxl" id="tokenomincs">
        <InnerContinerWraper maxWidth="xl">
          <CustomGrid container jc="start" g="20px">
            <CustomGrid item xs={12} jc="start">
              <SectionsHeading
              >
                tokenomics 
              </SectionsHeading>
            </CustomGrid>
            <TokenImageGrid item xs={12} sm={4} md={3}>
              <DataImage mw="70px" src={burn} alt="" />
              <Paragraph  textAlign="center" fontSize="clamp(1rem, 1vw + 0.8rem, 2rem)">
              liquidity burned        
              </Paragraph>
            </TokenImageGrid>
            <TokenImageGrid item xs={12} sm={4} md={3}>
              <DataImage mw="90px" src={tax} alt="" />
              <Paragraph textAlign="center" fontSize="clamp(1rem, 1vw + 0.8rem, 2rem)">
              No Taxes         
              </Paragraph>
            </TokenImageGrid>
            <CustomGrid item xs={0} md={4}>
            </CustomGrid>
            <TokenImageGrid item xs={12} sm={4} md={3}>
              <DataImage mw="100px" src={renounced} alt="" />
              <Paragraph  textAlign="center" fontSize="clamp(1rem, 1vw + 0.8rem, 2rem)">
              Renounced             
              </Paragraph>
            </TokenImageGrid>
          </CustomGrid>
        </InnerContinerWraper>
      <AbsoluiteImage
              mw='37%'
              b='0%'
              r='0%'
                // b='0%'
            src={tokenimage}/>
      </ContinerWraper>
    </>
  );
};

export default Tokenomics;
