import styled from "@emotion/styled";
import { Box, Container, Link } from "@mui/material";
import React from "react";
import { CustomGrid, DataImage } from "../../../Styles/style";
import x from "../../../../assets/images/x.png";
import tg from "../../../../assets/images/tg.png";
import radium from '../../../../assets/images/radium.png';
import dex from '../../../../assets/images/dex.png';
import solscan from '../../../../assets/images/solscan.png';
import icon from '../../../../assets/images/icon.png';
import Paragraph from "../../Paragraph";

const ContinerWraper = styled(Box)`
  position: relative;
  width: 100%;
  z-index: 0;
  background-color: #161616;
  padding: 50px 0!important;
  @media (max-width: 599px) {
    min-height: auto;
  }
`;

const Footer = () => {
  return (<>
    <ContinerWraper maxWidth="xxl" id="contact-us">
      <Container maxWidth='xl'>
        <CustomGrid container >
            <CustomGrid item xs={12} sm={4} fd="column" ai="start" g="10px">
              <Paragraph padding="10px 0" variant="h6">USEFULL LINKS</Paragraph>
              <Link sx={{textDecoration:"none"}} href="#home"><Paragraph>-Home</Paragraph></Link>
              <Link sx={{textDecoration:"none"}} href="#about"><Paragraph>-About us</Paragraph></Link>
              <Link sx={{textDecoration:"none"}} href="#tokenomincs"><Paragraph>-Tokenomics</Paragraph></Link>
              <Link sx={{textDecoration:"none"}} href="contact-us"><Paragraph>-Contact us</Paragraph></Link>
            </CustomGrid>
            <CustomGrid item xs={12} sm={4} fd="column" g="10px">
            <DataImage mw="100px" src={icon} alt="" />
              <Paragraph padding="10px 0" variant="h6" textAlign="center">RAJWIFHAT</Paragraph>
            </CustomGrid>
            <CustomGrid item xs={12} sm={4} fd="column" ai="end" g="10px">
            <Box
                sx={{
                  // padding: "7px 25px 5px",
                  margin: '20px 0px',
                  zIndex: '1',
                  display: 'flex',
                  gap:"10px",
                  justifyContent: 'space-between',
                }}
              >
                <Link href="https://t.me/rajwifSOL" target="_blank"><DataImage mw="45px" src={tg} alt="" /></Link>
                <Link href="https://twitter.com/rajwifSOL" target="_blank"><DataImage mw="45px" src={x} alt="" /></Link>
                <Link href="" target="_blank"><DataImage mw="45px" src={radium} alt="" /></Link>
                <Link href="" target="_blank"><DataImage mw="45px" src={dex} alt="" /></Link>
                <Link href="" target="_blank"><DataImage mw="45px" src={solscan} alt="" /></Link>
              </Box>
              <Paragraph>Rajwifhat@memecoin</Paragraph>
              </CustomGrid>
        </CustomGrid>
      </Container>
    </ContinerWraper>
        <Box bgcolor="#242424" width="100%">
            <Paragraph
              textAlign='center'
              padding='20px'
            >
               Copyright © 2024 RAJWIFHAT. All Rights Reserved
            </Paragraph>
        </Box>
  </>
  );
};

export default Footer;
