import styled from "@emotion/styled";
import { Grid, Typography } from "@mui/material";

const PageWrapper = styled.div`
  /* background-color: #000; */
  position: relative;
  overflow: hidden;
  /* overflow: hidden !important; */
`;
const DataImage = styled.img`
  width: 100%;
  max-width: ${(props) => (props.mw ? props.mw : "100%")};
  margin: ${(props) => (props.m ? props.m : "0px")};
  /* align-items: ${(props) => (props.ai ? props.ai : "center")}; */
  @media (max-width: 899px) {
    max-width: ${(props) => (props.mw899 ? props.mw899 : "")};
  }
  @media (max-width: 599px) {
    max-width: ${(props) => (props.mw599 ? props.mw599 : "")};
    margin: ${(props) => (props.m599 ? props.m599 : "")};
    display: ${(props) => (props.disp ? props.disp : "")};
    visibility: ${(props) => (props.vis ? props.vis : "")};
  }
`;
const AbsoluiteImage = styled.img`
  width: 100%;
  position: absolute;
  bottom: ${(props) => (props.b ? props.b : "")};
  right: ${(props) => (props.r ? props.r : "")};
  max-width: ${(props) => (props.mw ? props.mw : "100%")};
  margin: ${(props) => (props.m ? props.m : "0")};
  /* align-items: ${(props) => (props.ai ? props.ai : "center")}; */
  @media (max-width: 999px) {
    max-width: ${(props) => props.mw999};
  }
  @media (max-width: 599px) {
    max-width: ${(props) => (props.mw599 ? props.mw599 : "100%")};
    padding: ${(props) => (props.m599 )};
    position: relative;
  }
`;
const SectionHeading = styled(Typography)`
  padding: ${(props) => (props.p ? props.p : "0px")};
  margin: ${(props) => (props.m ? props.m : "0")};
  text-align: ${(props) => (props.ta ? props.ta : "center")};
  font-size: ${(props) => (props.fs ? props.fs : "60px")};
  font-family: ${(props) => (props.fm ? props.fm : "mickerblack")};
  line-height: ${(props) => (props.lh ? props.lh : "1")};
  position: relative;
  text-transform: none;
  z-index: 9;
  color: ${(props) => (props.c ? props.c : "white")};
  @media (max-width: 1175px) {
    text-align: ${(props) => props.ta599};
    padding: ${(props) => props.p599};
    font-size: ${(props) => (props.fs599 ? props.fs1175 : "76px")};
  }
  @media (max-width: 1024px) {
    text-align: ${(props) => props.ta599};
    padding: ${(props) => props.p599};
    font-size: ${(props) => (props.fs599 ? props.fs1024 : "62px")};
  }
  @media (max-width: 599px) {
    text-align: ${(props) => props.ta599};
    padding: ${(props) => props.p599};
    font-size: ${(props) => (props.fs599 ? props.fs599 : "32px")};
  }
`;

const CustomGrid = styled(Grid)`
  display: flex;
  width: 100%;
  flex-direction: ${(props) => (props.fd ? props.fd : "row")};
  align-items: ${(props) => (props.ai ? props.ai : "center")};
  justify-content: ${(props) => (props.jc ? props.jc : "center")};
  padding: ${(props) => (props.p ? props.p : "0px")};
  margin: ${(props) => (props.m ? props.m : "0")};
  gap: ${(props) => (props.g ? props.g : "0")};
  @media (max-width: 599px) {
    flex-direction: ${(props) => props.fd599};
  }
`;
const ColumnGrid = styled(Grid)`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.ai ? props.ai : "center")};
  justify-content: ${(props) => (props.jc ? props.jc : "center")};
  padding: ${(props) => (props.p ? props.p : "0px")};
  margin: ${(props) => (props.m ? props.m : "0")};
  @media (max-width: 599px){
    justify-content: ${(props) => (props.jc599 ? props.jc599 : "")} !important;
    align-items: ${(props) => (props.ai599 ? props.ai599 : "")} !important;
  }
`;

export {
  PageWrapper,
  DataImage,
  AbsoluiteImage,
  SectionHeading,
  CustomGrid,
  ColumnGrid,
};
