import { Container } from "@mui/material";
import React from "react";
import DesktopMenu from "./component/DesktopMenu";
import MobileMenu from "./component/MobileMenu";
import CustomButton from "../CustomBtn";

const LockerMenu = (props) => {
  const menuList = [
    // You can pass 4 perameters for menu title, link, target and customClass
    {
      title: "Home",
      link: "#home",
    },
    {
      title: "About us",
      link: "#about",
    },
    {
      title: "Tokenomics",
      link: "#tokenomincs",
    },
    {
      title: "Contact us",
      link: "#contact-us",
    },
    {
      title: <CustomButton>BUY Now</CustomButton>,
      link: "",
      target: "_blank",
      p:"0 0px 0 20px"
    },
    
  ];
  return (
    <Container maxWidth="xl" data-aos="fade-down">
      <DesktopMenu menuList={menuList} />
      <MobileMenu menuList={menuList} />
    </Container>
  );
};

export default LockerMenu;
