import React from "react";
import { Box, Container, Link } from "@mui/material";
import styled from "@emotion/styled";
import LockerMenu from "../../LockerMenu";
import { CustomGrid, DataImage } from "../../../Styles/style";
import Paragraph from "../../Paragraph";
import bg from '../../../../assets/images/bg.mp4';
import mobileBg from '../../../../assets/images/mobileBg.mp4';
import tg from '../../../../assets/images/tg.png';
import x from '../../../../assets/images/x.png';
import radium from '../../../../assets/images/radium.png';
import dex from '../../../../assets/images/dex.png';
import solscan from '../../../../assets/images/solscan.png';
import SectionsHeading from "../../SectionsHeading";

const ContinerWraper = styled(Box)`
  overflow: hidden;
  min-height: 100dvh;
  width: 100%;
  padding: 0 !important;
  position: relative; 
  @media (max-width: 599px) {
    min-height: 100vh;
    background-position: center;
    background-size: 100% 100%;
    background-image: none;
  }
`;

const InnerContinerWraper = styled(Container)`
  width: 100%;
  /* padding: 0 50px !important; */
  z-index: 1;
  @media (max-width: 599px) {
    background-position: left;
    padding: 0 20px !important;
    background-image: none;
  }
`;
const AbsoluiteVideo = styled.video`
  width: 100%;
  position: absolute;
  bottom: ${(props) => (props.b ? props.b : "0")};
  right: ${(props) => (props.r ? props.r : "0")};
  max-width: ${(props) => (props.mw ? props.mw : "100%")};
  margin: ${(props) => (props.m ? props.m : "0")};
  min-height: 100vh;
  object-fit: cover;
  z-index: -1;
  display: block;

  /* align-items: ${(props) => (props.ai ? props.ai : "center")}; */
  @media (max-width: 999px) {
    max-width: ${(props) => props.mw999};
  }
  @media (max-width: 499px) {
  display: none;

    max-width: ${(props) => (props.mw599 ? props.mw599 : "100%")};
    padding: ${(props) => (props.m599 )};
  }
`;
const MobileAbsoluiteVideo = styled.video`
  width: 100%;
  position: absolute;
  bottom: ${(props) => (props.b ? props.b : "0")};
  right: ${(props) => (props.r ? props.r : "0")};
  max-width: ${(props) => (props.mw ? props.mw : "100%")};
  margin: ${(props) => (props.m ? props.m : "0")};
  min-height: 100vh;
  object-fit: cover;
  z-index: -1;
  display: none;
  /* align-items: ${(props) => (props.ai ? props.ai : "center")}; */
  @media (max-width: 999px) {
    max-width: ${(props) => props.mw999};
  }
  @media (max-width: 499px) {
  display: block;

    max-width: ${(props) => (props.mw599 ? props.mw599 : "100%")};
    padding: ${(props) => (props.m599 )};
  }
`;
const HeroSection = () => {
  return (
    <>
      <ContinerWraper id="home">
        <LockerMenu />
        <AbsoluiteVideo src={bg} autoPlay muted loop />
        <MobileAbsoluiteVideo src={mobileBg} autoPlay muted loop />
        <InnerContinerWraper maxWidth='xl'>
          <CustomGrid container jc="start">
            <CustomGrid ai="start"
              fd="column" item xs={12} sm={6} md={6}>
                <SectionsHeading
              >
                IS RAJWIFHAT
              </SectionsHeading>
              <Paragraph maxWidth="580px">
              Picture this: Raj, the visionary co-founder of Solana, donning a hat. Not just any hat, mind you. It's a hat that transcends space, time, and fashion norms. It’s like the Mona Lisa wearing a snapback, or Einstein rocking a beanie. The hat itself is a cryptographic masterpiece, intricately woven with blockchain magic and sprinkled with stardust, transcending space, time and charts!
              </Paragraph>
              <div
                // item
                // xs={12}
                // data-aos="fade-up"
                style={{
                  // padding: "7px 25px 5px",
                  margin: '20px 0px',
                  zIndex: '1',
                  display: 'flex',
                  gap:"15px",
                  justifyContent: 'space-between',
                }}
              >
                <Link href="https://t.me/rajwifSOL" target="_blank"><DataImage mw="45px" src={tg} alt="" /></Link>
                <Link href="https://twitter.com/rajwifSOL" target="_blank"><DataImage mw="45px" src={x} alt="" /></Link>
                <Link href="" target="_blank"><DataImage mw="45px" src={radium} alt="" /></Link>
                <Link href="" target="_blank"><DataImage mw="45px" src={dex} alt="" /></Link>
                <Link href="" target="_blank"><DataImage mw="45px" src={solscan} alt="" /></Link>
              </div>
            </CustomGrid>
          </CustomGrid>
        </InnerContinerWraper>
      </ContinerWraper>
    </>
  );
};

export default HeroSection;
