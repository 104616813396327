import React from 'react'
import styled from '@emotion/styled'
import { Button } from '@mui/material'
import bg from "../../../assets/images/btnBg.png"
const CustomBtn = styled(Button)`
    background: ${props => props.bgc ? props.bgc : "transparent"};
    font-size: ${props=> props.fs ? props.fs : "16px"};
    border-radius: 0px;
    background-image: url(${bg});
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    /* min-width:  ${props=> props.w ? props.w: "150px"}; */
    font-family: ${props=> props.fm ? props.fm : "glacious"};
    margin:  ${props=> props.m ? props.m : "10px 0px"};
    width: 100%;
    max-width: 150px;
    padding:  ${props=> props.p ? props.p : "7px 20px"};
    color:${props => props.c ? props.c : "#000"};
    /* border: ${props => props.b ? props.b : "1px solid #FCD5A5"}; */
    transition: 1s;
    text-transform: none;
    cursor: ${props => props.cur ? props.cur : "pointer"};
    /* box-shadow: 0px 5px 10px #0000007b;
    text-shadow: 0px 3px 4px #000000; */
    z-index: 1;
    :hover{
      color: ${props => props.hc ? props.hc : "#000"};
      /* background: ${props => props.hbgc ? props.hbgc : "transparent"}; */
      
    }
    @media(max-width:599px){
      padding:  ${props=> props.p ? props.p : "4px 20px"};
      font-size: ${props=> props.fs599 ? props.fs599 : "12px"};
  }

` 

const CustomButton = (props) => {
  const {children} = props
  return (
    <CustomBtn variant='filled' {...props}>
        {children}
    </CustomBtn>
  )
}

export default CustomButton