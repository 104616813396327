import React from "react";
import { Box, Container } from "@mui/material";
import styled from "@emotion/styled";
import { CustomGrid, DataImage } from "../../../Styles/style";
import aboutBg from '../../../../assets/images/aboutBg.png';
import wif from '../../../../assets/images/wif.png';
import raj from '../../../../assets/images/raj.png';
import wifraj from '../../../../assets/images/wifraj.png';
import Paragraph from "../../Paragraph";
import SectionsHeading from "../../SectionsHeading";

const ContinerWraper = styled.div`
  overflow: hidden;
  min-height: calc(100vh - 40px);
  width: 100%;
  background-image: url(${aboutBg});
  background-position: center;
  background-size: cover;
  padding: 20px 0 !important;
  position: relative;
  z-index: 0;
  @media (max-width: 599px) {
    /* min-height: auto; */
  }
`;

const InnerContinerWraper = styled(Container)`
  min-height: calc(100vh - 40px);
  width: 100%;
  display: grid;
  place-items: center;
  z-index: 1;
  @media (max-width: 599px) {
    background-position: left;
    background-image: none;
  }
`;


const AboutSection = () => {
  return (
    <>
      <ContinerWraper maxWidth="xxl" id="about">
        <InnerContinerWraper maxWidth='lg'>
          <CustomGrid container>
            <CustomGrid item xs={12}>
              <SectionsHeading
              >
                Whats rAJWifHAT 
              </SectionsHeading>
            </CustomGrid>
            <CustomGrid item xs={12} md={3}>
            <DataImage mw="200px" src={wif} alt="" />
            </CustomGrid>
            <CustomGrid item xs={12} md={1}>
              <SectionsHeading
              >
                +
              </SectionsHeading>
            </CustomGrid>
            <CustomGrid item xs={12} md={3}>
            <DataImage mw="200px" src={raj} alt="" />
            </CustomGrid>
            <CustomGrid item xs={12} md={1}>
              <SectionsHeading
              >
                =
              </SectionsHeading>
            </CustomGrid>
            <CustomGrid item xs={12} md={3}>
            <DataImage mw="200px" src={wifraj} alt="" />
            </CustomGrid>
            <CustomGrid item xs={12} p="20px 0">
              <Paragraph maxWidth="580px" textAlign="center">
              Behold, the majestic Rajwifhat—it's the same old Raj we've come to know and love, but with a fucking hat!
              <br /> <br />
              Clearly, the universe was incomplete without this masterpiece..
              </Paragraph>
            </CustomGrid>
            <CustomGrid item xs={12} p="10px 0">
              <Box bgcolor="#fff" borderRadius="10px" padding="5px 20px">
              <Paragraph  textAlign="center" fontSize="clamp(1rem, 1vw + 0.8rem, 2rem)" maxWidth="580px" color="#000">
                NO BUT I GOT THAT RAJ IN ME              
              </Paragraph>
              </Box>
            </CustomGrid>
          </CustomGrid>
        </InnerContinerWraper>
      </ContinerWraper>
    </>
  );
};

export default AboutSection;
